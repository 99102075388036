<template>
  <div class="block-wrapper">
    <div v-if="localArticle">
      <div v-if="localArticle.deleted" class="entity-alert-deleted">Артикул удален !</div>
      <div v-if="localArticle" class="entity-title-block">
        <div class="entity-title-block__title">
          Артикул:&nbsp;
          <span v-if="localArticle.id" class="a-bold"> {{ localArticle.id }}. </span>
          &emsp;{{ localArticle.name }}
        </div>
      </div>
      <div v-else class="entity-title-block">
        <div class="entity-title-block__title">Новый артикул</div>
      </div>
      <div class="entity-details my-20">
        <div class="entity-details_block">
          <AInput
            v-model="localArticle.name"
            :read-only="!accessToChange"
            class="entity-details__desc"
            label="Название артикула"
            :error="getErrMess('name')"
          />
          <AInput
            v-model.trim="localArticle.code"
            :read-only="!accessToChange"
            class="entity-details__desc"
            label="Код артикула"
            :error="getErrMess('code')"
          />
        </div>
        <AInput
          v-model="localArticle.desc"
          textarea
          :read-only="!accessToChange"
          class="entity-details__desc"
          label="Общее описание артикула"
          :error="getErrMess('desc')"
        />
        <div class="mt-10">
          <AInput
            v-model="localArticle.techComment"
            textarea
            :read-only="!accessToChange"
            class="entity-details__techComment"
            label="Технический комментарий"
            :error="getErrMess('techComment')"
          />
        </div>
      </div>
      <div class="entity-details my-20">
        <div class="entity-file-block my-20">
          <div class="entity-file-block__mockup">
            <div>
              <AInputFile v-model="fileToUpload.front" max="5" greyish :formats="mockupFormats">
                <div class="entity-file-block__mockup-content ml-10 pt-5">
                  Загрузить мокап (перед)
                </div>
                <template #icon>
                  <Fa
                    name="icon"
                    icon="file-image"
                    class="icon faicon"
                    style="width: 30px; height: 30px"
                  />
                </template>
                <img
                  v-if="fileToUpload.front"
                  class="image-file"
                  width="90px"
                  height="110px"
                  :src="fileToUpload.front.url"
                  alt="мокап перед"
                />
              </AInputFile>
              <img
                v-if="!fileToUpload.front && localArticle.mockup.front"
                class="image-file"
                width="90px"
                height="110px"
                :src="getLink(localArticle.mockup.front)"
                alt="мокап перед"
              />
              <div v-if="formErrors[`mockup.front`]" class="validation-error-items">
                {{ formErrors[`mockup.front`] }}
              </div>
            </div>

            <div>
              <AInputFile v-model="fileToUpload.back" max="5" greyish :formats="mockupFormats">
                <div class="entity-file-block__mockup-content ml-10 pt-5">
                  Загрузить мокап (спинка)
                </div>
                <template #icon>
                  <Fa
                    name="icon"
                    icon="file-image"
                    class="icon faicon"
                    style="width: 30px; height: 30px"
                  />
                </template>
                <img
                  v-if="fileToUpload.back"
                  class="image-file"
                  width="90px"
                  height="110px"
                  :src="fileToUpload.back.url"
                  alt="мокап спинка"
                />
              </AInputFile>
              <img
                v-if="!fileToUpload.back && localArticle.mockup.back"
                class="image-file"
                width="90px"
                height="110px"
                :src="getLink(localArticle.mockup.back)"
                alt="мокап спинка"
              />
              <div v-if="formErrors[`mockup.back`]" class="validation-error-items">
                {{ formErrors[`mockup.back`] }}
              </div>
            </div>
          </div>
        </div>
        <div class="entity-file-block__mockup">
          <div>
            <AInputFile v-model="fileToUpload.plain" max="5" greyish :formats="mockupFormats">
              <div class="entity-file-block__mockup-content ml-10 pt-5">Загрузить раскладку</div>
              <template #icon>
                <Fa
                  name="icon"
                  icon="file-image"
                  class="icon faicon"
                  style="width: 30px; height: 30px"
                />
              </template>
              <img
                v-if="fileToUpload.plain"
                id="plain-img-1"
                class="image-file"
                width="220px"
                height="110px"
                :src="fileToUpload.plain.url"
                alt="раскладка"
              />
            </AInputFile>
            <img
              v-if="!fileToUpload.plain && localArticle.mockup.plain"
              id="plain-img-2"
              class="image-file"
              width="220px"
              height="110px"
              :src="getLink(localArticle.mockup.plain)"
              alt="раскладка"
            />

            <div v-if="formErrors[`mockup.plain`]" class="validation-error-items">
              {{ formErrors[`mockup.plain`] }}
            </div>
          </div>
          <div v-if="plainImgInfoText" class="plain-img-info">
            <div :class="plainImgInfoText.text">
              Ш / В = {{ plainImgInfo.width }} px. / {{ plainImgInfo.height }} px. ≈
              {{ plainImgInfoText.imgRatio }}
            </div>
          </div>
        </div>
        <!-- <div class="entity-file-block">
          <div class="entity-file-block__mockup">
            <div>
              <AInputFile v-model="fileToUpload.articleFile" max="70" greyish :formats="null">
                <div class="entity-file-block__mockup-content ml-10">
                  Загрузить файл артикула (&nbsp;{{ articleFileFormats.join() }}...&nbsp;)
                </div>
                <template #icon>
                  <Fa
                    name="icon"
                    icon="file-pdf"
                    class="icon faicon"
                    style="width: 30px; height: 30px"
                  />
                </template>
              </AInputFile>
            </div>
            <div v-if="fileToUpload.articleFile" class="entity-file-block__article-name">
              <ALabel label="Название загружаемого файла" :value="fileToUpload.articleFile.name" />
            </div>
            <div v-if="!fileToUpload.articleFile && localArticle.file">
              <AButton light @click="openWindow(localArticle.file)"> Скачать артикула </AButton>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import accessMixin from '@/mixin/accessMixin.js';
import validateMixin from '@/mixin/validateChild.js';
import { getLink } from '@/utils/getStorageLink.js';
import { MOCKUP_FORMATS } from '../../../../constants';

export default {
  name: 'ArticleBlock',
  mixins: [accessMixin, validateMixin],
  props: {
    selectedEntity: {
      type: Object,
      default: null,
    },
    creatingEntity: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:selectedEntity'],
  data() {
    return {
      mockupFormats: [...MOCKUP_FORMATS],
      articleFileFormats: ['pdf', 'ai', 'ait', 'eps', 'epsf', 'ps'],
      fileToUpload: {
        front: null,
        back: null,
        plain: null,
        articleFile: null,
      },
      plainImgInfo: {
        width: 0,
        height: 0,
      },
      plainImg: null,
    };
  },
  computed: {
    localArticle: {
      get() {
        return this.selectedEntity;
      },
      set(val) {
        this.$emit('update:selectedEntity', val);
      },
    },
    accessToChange() {
      return this.isCanChangeAssortment && !this.selectedEntity?.deleted && !this.loading;
    },
    plainImgInfoText() {
      if (!this.plainImgInfo.width || !this.plainImgInfo.height) {
        return null;
      }
      const imgRatio = parseFloat((this.plainImgInfo.width / this.plainImgInfo.height).toFixed(1));
      const result = { imgRatio, text: null };
      if (imgRatio <= 1.3) {
        result.text = 'green';
        return result;
      }
      if (imgRatio <= 1.6) {
        result.text = 'yellow';
        return result;
      }
      result.text = 'red';
      return result;
    },
  },
  watch: {
    fileToUpload: {
      handler: function (val) {
        if (val?.plain?.url) {
          this.img = document.createElement('img');
          this.img.onload = () => {
            // console.log(img.naturalHeight, this.plainImgInfo);
            this.plainImgInfo.width = this.img.naturalWidth;
            this.plainImgInfo.height = this.img.naturalHeight;
            this.img.remove();
          };
          this.img.src = val.plain.url;
        }

        this.$emit('update:selectedEntity', { ...this.localArticle, fileToUpload: val });
      },
      deep: true,
    },
  },
  created() {
    this.getLink = getLink;
  },
  methods: {
    openWindow(url) {
      window.open(getLink(url, true), '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
@include assortment-base-entity;
.entity-file-block {
  &__mockup {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    margin: 20 0px;
    div {
      justify-content: center;
      align-self: end;
    }
    img {
      width: 100%;
      height: 100%;
    }
    .plain-img-info {
      .red {
        color: $color-red;
      }
      .yellow {
        color: $color-wanring;
      }
      .green {
        color: $color-apple;
      }
    }

    &-content {
      display: flex;
      height: 100%;
      width: 100%;
      // margin: auto;
      justify-content: center;
    }
  }
}
.validation-error-items {
  color: $color-red;
  margin-left: 15px;
  margin-top: 15px;
}
</style>
